/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { withStyles } from '@material-ui/core/styles';
import { Component } from 'react';

import { TitleIcon } from '@findep/mf-landings-core'
import { Typography, Grid, Button, Box, Snackbar, Container, MenuItem } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

// import ManagerCard from '../Referencias/ManagerRefernecia'
import ReferenciaCard from '../forms/Referencias/ReferenciaCard'

import HowToRegIcon from '@material-ui/icons/HowToReg';
// import TrendingFlatIcon from '@material-ui/icons/TrendingFlat';
// import CarouselImg from '../../../components/img/CarouselImg'

// import { InsuranceService } from '../../../services/LandingV4/insurance'
import { navigate } from '../../helpers/queryNavigate';
import { CataloguesService } from '../../services/LandingV4/catalogues';
import phoneValidate from '../../helpers/phoneValidate'
import replaceAcents from '../../helpers/replaceAcents';
import { Referencia } from '../../services/LandingV4';
import { GlobalStateFooterContext } from '../../context/GlobalStateFooterContext';
import getTenant from '../../helpers/getTenant';

const root = css`
    padding: 1rem;
`
const text = css`
    padding: 1rem;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: 0.15px;
    color: rgba(0, 0, 0, 0.87);
`


const stleAlert = css`
float: right;
position: -webkit-sticky;
position: fixed;
bottom: 15px;
right: 0;
z-index:100;
`

const useStyles = (theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
});

const catalogues = new CataloguesService();

class PersonReferencias extends Component {
    constructor(props) {
        super(props)
        this.state = {
            flujo: !((typeof window != 'undefined') && sessionStorage.getItem('flujo') === null) ? (typeof window != 'undefined') ? JSON.parse(sessionStorage.getItem('flujo')) : '' : '',
            generarError: true,
            beneficiaries: [],
            textAlert: '',            
            openBackDrop: false,
            errorService: false,
            pageInfo: {
                "title": '',
                "subtitle": '',                
                "path": '',
                "requiredReferences": ''
            },
            relaciones: [],            
            data: [{ names: '', lastName: '', mothersName: '', phone: '', relacion: '', razon_social: '', requiredReferences: '' }],
            unitError: { names: false, lastName: false, mothersName: false, phone: false },            
            btnSubmit: true,
            _errors: [{
                names: {
                    error: false,
                    errorType: 'Campo obligatorio',
                },
                lastName: {
                    error: false,
                    errorType: 'Campo obligatorio',
                },
                mothersName: {
                    error: false,
                    errorType: '',
                },
                razon_social: {
                    error: false,
                    errorType: ''
                },
                phone: {
                    error: false,
                    errorType: 'Se requiere un numero de 10 dígitos',
                }
            }]
        }    
    }
    
    static contextType = GlobalStateFooterContext;

    async componentDidMount() 
    {                                
        //GET TENANT        
        const tenantValue = await getTenant(this.state.flujo.creditApplicationId)
        this.context.setIsDynamic(true)
        this.context.setTenantFooter(tenantValue)
        
        //Obtenemos referencia-comercial o referencia-personal    
        const referencia = this.props.pageContext.pagePath.split('/').pop();             
        
        if(referencia === 'referencias-personales') {      
            const getDataPersonal = await catalogues.getReferencesPersonal(this.state.flujo.creditApplicationId);                     
            const { referenceTypes, requiredReferences } = getDataPersonal.data;                        
            let data =[];                                    
            let _errors = [];
            for(let i = 0; i < requiredReferences; i++){                
                data=[...data, { names: '', lastName: '', mothersName: '', phone: '', relacion: '', "requiredReferences": requiredReferences }]         
                _errors=[..._errors, {
                    names: {
                        error: false,
                        errorType: 'Campo obligatorio',
                    },
                    lastName: {
                        error: false,
                        errorType: 'Campo obligatorio',
                    },
                    mothersName: {
                        error: false,
                        errorType: '',
                    },                    
                    phone: {
                        error: false,
                        errorType: 'Se requiere un numero de 10 dígitos',
                    }
                }]
            }
            this.setState({
                ...this.state, 
                data, _errors, 
                relaciones: referenceTypes, 
                pageInfo: {
                    "title": "Referencias Personales",
                    "subtitle": `Por favor, ingresa los datos de ${requiredReferences} referencias personales (amigos, familiares, gente de confianza, etc.).`,                
                    "path": referencia,                
                }
            
            })            
          } else {
            const getDataComercial = await catalogues.getReferencesCommercial(this.state.flujo.creditApplicationId);
            const { referenceTypes,requiredReferences } = getDataComercial.data;  
            this.setState({ pageInfo: 
                {
                    "title": "Referencias Comerciales",
                    "subtitle": `Por favor, ingresa los datos de ${requiredReferences} referencias comerciales (clientes, proveedores, etc.).`,                
                    "path": referencia,
                    "requiredReferences": requiredReferences
                }            
            })
            let data =[];                                    
            let _errors = [];
            for(let i = 0; i < requiredReferences; i++){                
                data=[...data, { names: '', lastName: '', mothersName: '', phone: '', razon_social: '', relacion: '', "requiredReferences": requiredReferences }]         
                _errors=[..._errors, {
                    names: {
                        error: false,
                        errorType: 'Campo obligatorio',
                    },
                    lastName: {
                        error: false,
                        errorType: 'Campo obligatorio',
                    },
                    mothersName: {
                        error: false,
                        errorType: '',
                    },
                    razon_social: {
                        error: false,
                        errorType: ''
                    },
                    phone: {
                        error: false,
                        errorType: 'Se requiere un numero de 10 dígitos',
                    }
                }]
            }
            this.setState({...this.state, data, _errors, relaciones: referenceTypes})            
        }                                                      
    }

    componentDidUpdate(prevProp, prevState) {
        let state = [];
        
        this.state.data.map((item) => (
            Object.values(item).map(value => state = [...state, value])            
        ))
        let errors = []
        this.state._errors.map(item => Object.values(item).map(value => errors = [...errors, value.error]));        
                
        let stateFilter = state.filter(item => item);
        let errorsFilter = errors.filter(item => item === true);        

        if (stateFilter.length === state.length && errorsFilter.length < 1) {
            if (!prevState.btnSubmit) {
              this.setState({ btnSubmit: true })
            }
          } else if (prevState.btnSubmit) {
            this.setState({ btnSubmit: false })
          }
    }

    handleChange = (e, component, idx) => {        
        const campo = e.target.name;    
        // const newValue = component.value.trim().split(/[,\s]+/).join(" ");
                   
        const data = [...this.state.data]
        data[idx][campo] = component.value              
                    
        this._handleError(campo, {}, idx, false)
        
        const tmp = Object.assign({}, this.state, {data})
        this.setState(tmp, () => {
    
          if (campo === "phone") {              
              this.samePhoneNumberError();    
              if (this.state.data[idx].phone.length >= 10) {                
                this.verificaTelefono(idx)              
            } else {
                const _errors = [...this.state._errors]
                _errors[idx].phone = {
                    error: true,
                    errorType: 'Ingrese un número de 10 dígitos'
                }
                
                this.setState({ ...this.state, srv_tel: true, _errors })
            }
          }
        })   
        
      }  

    samePhoneNumberError = () => {        
        const _errors = [...this.state._errors];
        if(this.state.data[0].phone === this.state.data[1].phone && this.state.data[1].phone.length >= 10) {                         
                _errors[1].phone = {
                    error: true,
                    errorType: 'Haz ingresado el mismo número de teléfono'
                }            
                this.setState({ ...this.state, srv_tel: true, _errors })
            } else {
                if( this.state.data[0].phone.length >= 10 && this.state.data[1].phone.length >= 10) {
                    _errors[1].phone = {
                        error: false,
                        errorType: ''
                    }            
                    this.setState({...this.state, srv_tel: false })
                }
        }            
    }
    
      handleChangeSelect = (prop, component, idx) => {
          const data = [...this.state.data];
          data[idx][prop] = component.value                
        this._handleError(prop, {}, idx, false)
        const tmp = Object.assign({}, this.state, {data})
        this.setState(tmp, () => { })                    
      }
      
    
    handleError = (e) => {
        this.setState({ generarError: e.value })
    }

    _handleError = (prop, data, idx, bool = true) => {
        const _errors = [...this.state._errors]
        _errors[idx][prop] = {
          ..._errors[idx][prop],
          error: bool
        }
        this.setState({ ...this.state, _errors })
      }

    handleData = (e) => {
        this.setState({ beneficiaries: e })
    }

    handleNextStep = async () => {            
        const referencia = new Referencia(this.state.flujo.creditApplicationId);            
        let navigateUrl = this.props.pageContext.next.path;
        const page = this.props.pageContext.pagePath.split('/').pop();                     
        const path = (page === 'referencias-personales' ? '/references/personal' : '/references/commercial')        

        const references = [];        
        const information = [...this.state.data];
        information.map(item => (
            generarReferencia(item)            
        ))

        function generarReferencia (item) {
            delete item.requiredReferences                         
            const referenciaObjeto = {};
            Object.keys(item).forEach(key => {
                let value = item[key].trim().split(/[,\s]+/).join(" ");
                key === 'names' 
                ? referenciaObjeto['firstName'] = value
                : key === 'phone' 
                ? referenciaObjeto['mobileNumber'] = value
                : key === 'relacion' 
                ? referenciaObjeto['referenceTypeId'] = value
                : key === 'razon_social' 
                ? referenciaObjeto['businessName'] = value
                : referenciaObjeto[key] = value                
            });
            
            references.push(referenciaObjeto);                    
        }
                
        console.log(references)      
        await referencia.send(path, references)
        .then((response) => {            
            navigate(navigateUrl);
            console.log(response)
        })
        .catch(error => {
            console.log(error)
            this.setState({ errorService: true, btnSubmit: true })
        })
    }

    async verificaTelefono(idx) {
        const ressponse = await phoneValidate(this.state.flujo.creditApplicationId, this.state.data[idx].phone)
        if (ressponse) {
          this.setState({ srv_tel: false })          
        } else {
            const _errors = [...this.state._errors]
            _errors[idx].phone = {
                error: true,
                errorType: 'No fue posible validar tu teléfono intente de nuevo'
            }
          this.setState({ ...this.state, srv_tel: true, _errors })
        }
      }

    renderRelacion = () => {    
        const relacion = this.state.relaciones;
        
        return (relacion.length > 0) ? (
          relacion.map(row => (
            <MenuItem key={`${row.id}`} value={row.id}>{row.displayName.toUpperCase()}</MenuItem>
    
          ))
        ) : (
            <MenuItem value="">Selecciona una opción...</MenuItem>
        )
      }

    

    render() {
        const { classes } = this.props;
        return (
            <Container justify="center"  >
                <Grid item xs={12} >
                    {
                        this.state.textAlert !== '' && <Alert css={stleAlert} variant="filled" severity="error">{this.state.textAlert}</Alert>
                    }
                    <Grid container justify="center">
                        <TitleIcon title={this.state.pageInfo.title} icon={HowToRegIcon} textColor={true} />
                    </Grid>

                    <Box component="span" m={1} style={{ textAlign: "center" }}>
                        <Typography css={text}>
                            {this.state.pageInfo.subtitle}
                        </Typography>
                    </Box>                    
                </Grid>
                                 
                {
                    this.state.data.map((referencia, idx) => (
                        <Grid container spacing={2} justify="center">
                            <Grid item xs={12} md={6}>                            
                                <ReferenciaCard
                                    id={idx}
                                    onChange={this.handleChange}  
                                    submitStepTwo={this.handleNextStep}                                  
                                    onChangeSelect={this.handleChangeSelect}
                                    renderSelect={this.renderRelacion}
                                    onError={this.handleError}
                                    _handleError={this._handleError}
                                    textTitleCard="Referencia"                            
                                    path={this.state.pageInfo.path}                                                                                                    
                                    _errors={this.state._errors[idx]}                            
                                    flujo={this.state.flujo}                            
                                    data={this.state.data[idx]}
                                    activeButton={referencia.requiredReferences}
                                    btnSubmit={this.state.btnSubmit}
                                    activeInput={idx === 0 ? false : (!this.state._errors[idx-1].phone || this.state.data[idx-1].phone === '')}                                
                                />
                                <br />                        
                            </Grid>
                        </Grid>
                    ))
                }
                
                <Backdrop open={this.state.openBackDrop} className={classes.backdrop}>
                    <CircularProgress color="primary" />
                </Backdrop>
                <Snackbar open={this.state.errorService} autoHideDuration={6000} onClose={() => this.setState({ errorService: false })}>
                    <Alert variant="filled" severity="warning" onClose={() => this.setState({ errorService: false })}>
                        Lo sentimos, por el momento el servicio no se encuentra disponible
                    </Alert>
                </Snackbar>
            </Container>
        );
    }
}

export default withStyles(useStyles)(PersonReferencias);
